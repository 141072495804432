import React from "react";
import { Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box, Chip } from "@mui/material";
import { AnalyticsData } from "./LumenAIChatHistoryContainer";

interface LumenAIChatHistoryProps {
    analyticsData: AnalyticsData;
}

const zip = (a: string[], b: string[], c: string[][]) => a.map((k, i) => [k, b[i], c[i]]);

const LumenAIChatHistory: React.FC<LumenAIChatHistoryProps> = (props: LumenAIChatHistoryProps) => {
    return (
          <List>
            {/* Sender's message */}
            {zip(props.analyticsData.queries, props.analyticsData.responses, props.analyticsData.metadata).map((elem) => (
                <>
                <ListItem style={{ justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                      display: "flex",
                      flexDirection:"column",
                      alignItems:"flex-end",
                      maxWidth:"70%",
                      borderRadius:"10px",
                      backgroundColor:"#3f51b5",
                      color:"white",
                      padding:"10px"
                  }}
                >
                  <Typography variant="body2" color="inherit" style={{
                            maxWidth: '100%',
                            overflowWrap: 'break-word'
                          }}>
                    {elem[0]}
                  </Typography>
                </Box>
                <ListItemAvatar sx={{paddingLeft: '10px'}}>
                  <Avatar alt="Sender" src="https://img.icons8.com/external-kiranshastry-gradient-kiranshastry/64/external-user-interface-kiranshastry-gradient-kiranshastry.png" />
                </ListItemAvatar>
              </ListItem>
      
              <ListItem style={{ justifyContent: 'flex-start' }}>
                <ListItemAvatar>
                  <Avatar alt="Receiver" src="https://img.icons8.com/nolan/64/bot.png" />
                </ListItemAvatar>
                <Box
                  sx={{
                      display: "flex",
                      flexDirection:"column",
                      alignItems:"flex-end",
                      maxWidth:"70%",
                      borderRadius:"10px",
                      backgroundColor:"#3f51b5",
                      color:"white",
                      padding:"10px"
                  }}
                >
                  <Typography variant="body2" style={{
                            maxWidth: '100%',
                            overflowWrap: 'break-word'
                          }}>
                    {elem[1]}
                  </Typography>
                </Box>
              </ListItem>
              </>
            ))}
            {/* Add more messages as needed */}
          </List>
      );
}

export default LumenAIChatHistory;