import * as React from 'react';
import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { AnalyticsData } from './LumenAIChatHistoryContainer';

export interface LumenAIQueryAnalyticsTableProps {
    analyticsData: AnalyticsData[];
    setChatNumber: React.Dispatch<React.SetStateAction<number>>;
}

const LumenAIQueryAnalyticsTable: React.FC<LumenAIQueryAnalyticsTableProps> = (props: LumenAIQueryAnalyticsTableProps) => {

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {props.analyticsData.map((elem: AnalyticsData, index: number) => (
        <>
            <ListItem key={index} alignItems="flex-start">
                <ListItemButton onClick={() => {props.setChatNumber(index)}}>
                <ListItemText
                primary={<Typography noWrap style={{
                    maxWidth: '400px', // Set a maximum width for the text container
                    overflow: 'hidden', // Hide any overflowing content
                    textOverflow: 'ellipsis', // Show an ellipsis (...) when text overflows
                    whiteSpace: 'nowrap', // Prevent line breaks
                  }}>{elem.queries[0]}</Typography>}
                secondary={
                    <React.Fragment>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        noWrap style={{
                            maxWidth: '400px', // Set a maximum width for the text container
                            overflow: 'hidden', // Hide any overflowing content
                            textOverflow: 'ellipsis', // Show an ellipsis (...) when text overflows
                            whiteSpace: 'nowrap', // Prevent line breaks
                          }}
                    >
                        {elem.time}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        noWrap style={{
                            maxWidth: '400px', // Set a maximum width for the text container
                            overflow: 'hidden', // Hide any overflowing content
                            textOverflow: 'ellipsis', // Show an ellipsis (...) when text overflows
                            whiteSpace: 'nowrap', // Prevent line breaks
                          }}
                    >
                        {elem.responses[0]}
                    </Typography>
                    </React.Fragment>
                }
                />
                </ListItemButton>
            </ListItem>
            <Divider component="li" />
        </>
      ))}
    </List>
  );
}

export default LumenAIQueryAnalyticsTable