import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Button, Link, TextField } from '@mui/material';
import { UserButton } from '@clerk/clerk-react';
import lumenLogo from '../logo192.png'

const drawerWidth = 240;

const LumenAIDashboard = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft({ initialContent }: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [inputText, setInputText] = React.useState('');

  const handleInputChange = (event: any) => {
    setInputText(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={true}>
        <Toolbar style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', 
        backgroundImage: `linear-gradient(
          45deg,
          hsl(290deg 100% 36%) 0%,
          hsl(282deg 70% 45%) 2%,
          hsl(273deg 59% 50%) 5%,
          hsl(265deg 61% 54%) 9%,
          hsl(256deg 64% 57%) 17%,
          hsl(246deg 66% 60%) 30%,
          hsl(236deg 70% 61%) 53%,
          hsl(228deg 76% 58%) 78%,
          hsl(220deg 80% 54%) 92%,
          hsl(211deg 100% 46%) 100%
        )` }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <img style={{ height: '50px', width: '50px', marginRight: '8px' }} src={lumenLogo}></img>
            <Typography variant="h6" noWrap component="div">
              LumenAI Dashboard
            </Typography>
          </div>
          <UserButton/>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <List>
          {['Dashboard'].map((text, index) => (
            <Link underline='none' color="inherit" href={text === 'Dashboard' ? '/app' : text.toLowerCase()}>
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {text === 'Dashboard' && <DashboardIcon color='primary'/>}
                </ListItemIcon>
                <ListItemText primary={text} /> 
              </ListItemButton>
            </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <LumenAIDashboard open={true}>
        <DrawerHeader />
        {initialContent}
      </LumenAIDashboard>
    </Box>
  );
}
