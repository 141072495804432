import React from "react"
import { useEffect, useState } from "react"
import { Chip, Pagination, Paper, Typography } from "@mui/material"
import LumenAIQueryAnalyticsTable from "./LumenAIQueryAnalyticsTable"
import LumenAIChatHistory from "./LumenAIChatHistory"
import selectChat from '../storyline-laptop-with-speech-bubbles-and-audio-messages.png'
import loadingGif from '../clip-1026.gif'
import unauthorizedPng from '../worky-ai-web-design.png'
import { useUser } from '@clerk/clerk-react';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

export interface AnalyticsData {
    uuid: string;
    queries: string[];
    responses: string[];
    metadata: string[][];
    time: string;
}

const LumenAIChatHistoryContainer: React.FC = () => {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const [chatNumber, setChatNumber] = useState<number>(-1);
    const [page, setPage] = React.useState(1);
    const [numPages, setNumPages] = React.useState(0);
    const user = useUser();
    const api_key_dictionary: { [key: string]: string } = {
        "quicknode.com": "WafU53x2B8z5L9mHYwdvrze61uMUEoE7",
        "quiknode.io": "WafU53x2B8z5L9mHYwdvrze61uMUEoE7",
        "silimate.com": "293f342e5f164a9a8f27545f5a2b350f",
        "lumensearch.ai": "WafU53x2B8z5L9mHYwdvrze61uMUEoE7"
    };
    const api_key = api_key_dictionary[`${user.user?.emailAddresses[0].emailAddress.split('@')[1]}`] ?? ""

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        setChatNumber(-1);
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
              // console.log(user.user?.emailAddresses[0].emailAddress)
              const queryParams = new URLSearchParams({
                email: user.user?.emailAddresses[0].emailAddress ?? "",
              });
              const response = await fetch(`https://lumenaianalyticsprocessor.onrender.com/api/authenticate_email?${queryParams}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${api_key}`,
                  'Content-Type': 'application/json',
                },
              });
              // console.log(JSON.stringify(requestBody))
      
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const responsedata = await response.json();
              // console.log(responsedata)
              setAuthenticated(responsedata?.authenticated ?? false)
            } catch (error) {
                setAuthenticated(false)
                console.log(error)
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          try {
              const queryParams = new URLSearchParams({
                page: page.toString()
              });
              const response = await fetch(`https://lumenaianalyticsprocessor.onrender.com/api/read_analytics?${queryParams}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${api_key}`,
                  'Content-Type': 'application/json',
                },
              });
              // console.log(JSON.stringify(requestBody))
      
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
      
              const responsedata = await response.json();
              setAnalyticsData(responsedata);
              // console.log(responsedata)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, [page]);

    useEffect(() => {
        const fetchData = async () => {
          try {
              const response = await fetch(`https://lumenaianalyticsprocessor.onrender.com/api/page_count`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${api_key}`,
                  'Content-Type': 'application/json',
                },
              });
              // console.log(JSON.stringify(requestBody))
      
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
      
              const responsedata = await response.json();
              // console.log('num pages ',  responsedata.count)
              setNumPages(responsedata.count)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    return(
    <>
    {authenticated && !loading &&
        <>
        <div style={{ display: 'flex', height: '80vh' }}>
        <div style={{ flex: 1, padding: '16px', overflowY: 'scroll' }}>
        <Paper elevation={3}>
            <LumenAIQueryAnalyticsTable setChatNumber={setChatNumber} analyticsData={analyticsData}/>
        </Paper>
        </div>
        <div style={{ flex: 2, padding: '16px', overflowY: 'scroll' }}>
        <Paper elevation={3}>
            {chatNumber === -1 && <div style={{paddingTop: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}><img style={{width: '50%', height: '50%',objectFit: 'cover'}} src={selectChat}/><Typography variant='h5' style={{paddingTop: '70px', paddingBottom: '45px'}}>Select a Chat!</Typography></div>}
            {chatNumber !== -1 && <LumenAIChatHistory analyticsData={analyticsData[chatNumber]}/>}
        </Paper>
        </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: '70px' }}>
            <Pagination count={numPages} page={page} onChange={handlePageChange} size="large" />
        </div>
    </div>
    </>
  }
  {authenticated && loading &&
    <div style={{paddingTop: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={loadingGif}/>
    </div>
  }
  {!authenticated && 
    <div style={{paddingTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img style={{ height: '500px', width: '500px', marginRight: '8px' }} src={unauthorizedPng}/>
        <Typography variant="h5">
            Please schedule a meeting with us to set up your organization!
        </Typography>
        <Chip component="a" href="https://calendly.com/lumenai/lumenai-information-session" color="primary" style={{ marginTop: '10px' }} icon={<InsertInvitationIcon/>} label="Schedule a Time"></Chip>
    </div>
  }
  </>)
}

export default LumenAIChatHistoryContainer