import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import PersistentDrawerLeft from './components/LumenAIDashboard';
import { Text, Flex } from '@mantine/core';
import LumenAIQueryAnalyticsTable from './components/LumenAIQueryAnalyticsTable'
import { Divider, Grid, Paper, Typography } from '@mui/material';
import LumenAIChatHistoryContainer from './components/LumenAIChatHistoryContainer';
import { ClerkProvider, SignedIn, SignedOut, UserButton, useUser, RedirectToSignIn } from "@clerk/clerk-react";
import welcome from './urban-android-robot-sitting-at-the-reception-and-greeting-with-his-hand-up.png'

function App() {

  return (
    <Router>
      <ClerkProviderWithRoutes/>
    </Router>
  );
}

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();
  const clerkPubKey: string | undefined = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  return (
    <ClerkProvider publishableKey={clerkPubKey ?? ''} navigate={(to) => navigate(to)}>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>
        <Routes>
          <Route path="/app" element={<PersistentDrawerLeft initialContent={
            <LumenAIChatHistoryContainer/>} />} />
          <Route path="/" element={<Navigate to="/app" />} />
        </Routes>
      </SignedIn>
    </ClerkProvider>
  );
}

export default App;
